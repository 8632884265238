body {
  padding: 0;
  margin: 0;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.skeleton {
  background-color: rgba(240, 240, 240, 1);
  background-image: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1),
    rgba(255, 255, 255, 1),
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 500ms ease-in-out infinite;

  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
}

#ImageSequenceHero {
  z-index: 1;
}

#ImageSequenceHero #your-space {
  color: #fff;
}
#ImageSequenceHero #can-be {
  color: #fff;
}

#withProjectGreen #with {
  color: #000;
}
#withProjectGreen #ProjectGreen {
  color: #000;
}

@media (min-width: 1024px) {
  #ImageSequenceHero {
    position: fixed;
    width: 80%;
    margin: 25% 5%;
    font-size: large;
  }

  #ImageSequenceHero h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 4rem;
  }

  #ImageSequenceHero #can-be {
    position: relative;
    bottom: 0.3rem;
    right: 1.3rem;
    font-size: 5rem;
  }

  #ImageSequenceHero #brighter {
    position: relative;
    bottom: 0.9rem;
    right: 2.3rem;
    font-size: 7rem;
  }

  #withProjectGreen {
    position: fixed;
    width: 100%;
    margin: 30% 5%;
    font-size: large;
  }

  #withProjectGreen #with {
    position: relative;
    /* bottom: 0rem; */
    top: 1.8rem;
    right: 4rem;
    font-size: 4rem;
  }
  #withProjectGreen #ProjectGreen {
    position: relative;
    /* bottom: 0rem; */
    top: 1rem;
    right: 15.6rem;
    font-size: 6.3rem;
    color: #2edc9a;
  }
}

@media (max-width: 1023px) {
  #ImageSequenceHero {
    position: fixed;
    width: 100%;
    margin: 50% auto;
  }

  #ImageSequenceHero h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 4rem;
    padding: 2rem 0;
  }

  #ImageSequenceHero #brighter {
    position: relative;
    font-size: 5rem;
  }
}

#withProjectGreen {
  z-index: 1;
}

goones-body {
  overscroll-behavior: none;
}

goones-body {
  font-family: Arial Black, sans-serif;
  text-align: center;
  background: #000;
  /* height: 200vh; */
}
